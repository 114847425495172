import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import Footer from './Footer';
import Nav from './Nav';
import Typography from '../styles/Typography';

// curly brackets destructures props and grabs the children object
export default function Layout({ location, children }) {
  return (
    <>
      <Typography />
      <Nav location={location} />
      {children}
      <Footer />
    </>
  );
}
