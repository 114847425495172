import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/wwf-onesource-horizontal-logo-full-color-rgb.svg';

const NavStyles = styled.nav`
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: #eaeaea;
  color: var(--darkPurple);
  z-index: 2;
  transition: top 500ms ease-in-out;

  &.scroll-up {
    top: 0;
  }

  &.scroll-down {
    top: -100%;
  }

  &.scroll-up,
  &:focus-within {
    top: 0;
  }

  .links {
    display: flex;
    align-items: center;
    margin: 0 -1em;
    flex-direction: row;
  }

  .links a {
    display: inline-block;
    margin: 0 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  a {
    color: #0f0f0f;
    text-decoration: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .links {
      margin-top: 1rem;
      flex-direction: column;
      width: 90vw;

      a {
        margin-top: 0.5rem;
      }
    }
  }
`;

export default function Nav({ location }) {
  const path = location && location.pathname ? location.pathname : '';

  return (
    <NavStyles>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="OneSource Logo" height="50" weight="50" />
        </Link>
      </div>
      <div className="links">
        {path !== '/' ? <Link to="/">Home</Link> : ''}
        <Link to="/about-us">About Us</Link>
        <Link to="/news">In The News</Link>
        <Link to="/asset-library">Asset Library</Link>
        <Link to="/join">Join The Coalition</Link>
      </div>
    </NavStyles>
  );
}
