import { createGlobalStyle } from 'styled-components';

import publicSans from '../assets/fonts/PublicSans-VariableFont_wght.ttf';
import workSans from '../assets/fonts/WorkSans-VariableFont_wght.ttf';

const Typography = createGlobalStyle`
:root {
  --darkGreen: #009966;
  --darkGreenDarker: #00774f;
  --lightGreen: #33cc99;
  --lightBlue: #33cccc;
  --darkPurple: #211f3d;
  --textGrey: #555;
  --white: #fff;
}
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Public_Sans";
    src:
      url(${publicSans}) format('truetype supports variations'),
      url(${publicSans}) format('truetype-variations');
      font-weight: 100 1000;
  }
  @font-face {
    font-family: "Work_Sans";
    src:
      url(${workSans}) format('truetype supports variations'),
      url(${workSans}) format('truetype-variations');
    font-weight: 100 1000;
  }
}
  html {
    font-family: "Work_Sans", Work_Sans, sans-serif;
    font-size: 18px;
    color: #000;
  }
  p, li {
    letter-spacing: 0.5px;
    line-height: 26px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: "Public_Sans", Public_Sans, sans-serif;
    font-weight: normal;
    margin: 0;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  main {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: clamp(25px, 10vw, 50px);
    margin-bottom: clamp(25px, 10vw, 100px);
    @media (max-width: 1024px) {
      max-width: 80vw;
    }
  }
`;

export default Typography;
