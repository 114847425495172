import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  width: 100%;
  background-color: var(--darkPurple);
  text-align: center;
  padding: 10vh 0;
  color: #fff;
  a {
    color: #d0cfcf;
  }
  .contact {
    @media (max-width: 1024px) {
      width: 80vw;
      margin: 0 auto;
    }
  }
  .iconCredit {
    font-size: 10px;
  }
`;

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          homepage: allSanityHomepage {
            nodes {
              contactEmail
            }
          }
        }
      `}
      render={(data) => {
        const homepage = data.homepage.nodes[0];
        return (
          <FooterStyles>
            <div className="contact">
              <p>
                We welcome additional members and inquiries about these
                recommendations. Get in touch at<span> </span>
                <a href={`mailto:${homepage.contactEmail}`}>
                  {homepage.contactEmail}
                </a>
              </p>
            </div>
            <a href="https://www.worldwildlife.org/privacy">Privacy Policy</a>
            <p>&copy; World Wildlife Fund {new Date().getFullYear()}</p>
          </FooterStyles>
        );
      }}
    />
  );
}
